import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInstance } from '../../utils/axios'

export const fetchSchedules = createAsyncThunk(
  'schedule/fetchSchedules',
  async (data, thunkAPI) => {
    const result = await axiosInstance.get('schedules/')
    return result.data
  }
)

export const createSchedule = createAsyncThunk(
  'schedule/createSchedule',
  async (scheduleData, thunkAPI) => {
    console.log('schedule data: ', scheduleData)
    const result = await axiosInstance.post(`schedules/`, scheduleData)
    return result.data
  }
)

export const deleteSchedule = createAsyncThunk(
  'schedule/deleteSchedule',
  async (scheduleId, thunkAPI) => {
    await axiosInstance.delete(`schedules/${scheduleId}/`)
    return scheduleId
  }
)

export const updateSchedule = createAsyncThunk(
  'schedule/updateSchedule',
  async (scheduleData, thunkAPI) => {
    const {id: scheduleId, ...remData} = scheduleData
    const result = await axiosInstance.patch(`schedules/${scheduleId}/`, remData)
    return result.data
  }
)


export const fetchActions = createAsyncThunk(
  'schedule/fetchActions',
  async (data, thunkAPI) => {
    const result = await axiosInstance.get('actions/')
    return result.data
  }
)

export const fetchDays = createAsyncThunk(
  'schedule/fetchDays',
  async (data, thunkAPI) => {
    const result = await axiosInstance.get('days/')
    return result.data
  }
)

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState: {
    schedules: null,
    actions: null,
    days: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSchedules.fulfilled, (state, action) => {
      state.schedules = action.payload
    })
    .addCase(fetchActions.fulfilled, (state, action) => {
      state.actions = action.payload
    })
    .addCase(fetchDays.fulfilled, (state, action) => {
        state.days = action.payload
    })
    .addCase(deleteSchedule.fulfilled, (state, action) => {
      state.schedules = state.schedules.filter(s => s.id !== action.payload)
    })
    .addCase(createSchedule.fulfilled, (state, action) => {
      state.schedules = state.schedules.concat(action.payload)
    })
    .addCase(updateSchedule.fulfilled, (state, action) => {
      state.schedules = state.schedules.map(s => s.id === action.payload.id ? action.payload : s)
    })
  }
})

export const { setActions, deleteScheduleState } = scheduleSlice.actions
export default scheduleSlice.reducer