import { useState, useEffect } from "react"
import {useNavigate} from 'react-router-dom'
import { useClients } from '../../hooks/client'
import Button from "../../components/button"
import ClientCard from "../../components/client/ClientCard"
import ClientSearchFilter from "../../components/SearchFilter"
import SortButton from '../../components/SortButton'
import SortBox from "../../components/SortBox"

export const ClientListContainer = () => {
  const navigate = useNavigate()
  const [filterValue, setFilterValue] = useState('')
  const clients = useClients()
  const [sortedClients, setSortedClients] = useState()
  const [[sortFunction], setSortFunction] = useState( [(a, b) => 0])

  useEffect( 
    () => {
      setSortedClients([...clients].sort(sortFunction)) 
    }, 
  [clients, sortFunction])

  const clientNameSort = (a, b) => {
    if ( a.name < b.name ) return -1;
    if ( a.name > b.name ) return 1;
    return 0;
  }

  const clientDateSort = (a, b) => Number(new Date(a.registered)) - Number(new Date(b.registered))

  return (
    <div className="client-list-container">
      
      <div className="client-list-container__header">
          <ClientSearchFilter handleOnChange={e => setFilterValue(e.target.value)} value={filterValue} />
          <Button onClick={e => navigate('create/')} icon="add-outline"> Add </Button>
      </div>
      
      <div className="client-list-container__header-filter">
        <SortBox onClick={e => setSortFunction([clientNameSort]) }>Client Name </SortBox>
        <SortBox onClick={e => setSortFunction([clientDateSort]) }>Registration Date </SortBox>
      </div>
      
      <div className="client-list-container__content"> 
      { sortedClients && 
        sortedClients.filter(client => client.name.toLowerCase().includes(filterValue.toLowerCase()))
          .map( client =>  <ClientCard key={client.id} client={client} /> )}
      </div>
    </div>
  )
}

export default ClientListContainer