import React, { useState } from 'react';
import {useParams} from 'react-router-dom';
import classNames from 'classnames';
import {Form, Field} from 'react-final-form';
import Skeleton from 'react-loading-skeleton';
import SortBox from '../../components/SortBox';
import StorePicker from '../../components/store/StorePicker';
import DateTimeBox from '../../components/date/DateTimeBox';
import StatusPill from '../../components/status/StatusPill';
import {ClientScheduleItem, ClientScheduleCreateForm} from '../../components/client'
import {useDevice} from '../../hooks/device';
import {useStore} from '../../hooks/store';
import {useClients} from '../../hooks/client';
import { usePhotoBooth } from '../../hooks/photobooth';
import { useSTWWheel } from '../../hooks/stw';
import { useStoreVideos } from '../../hooks/video';
import { useDeviceSchedules } from '../../hooks/schedule';
import { useConfirmation, useNotification } from '../../hooks';
import { useDispatch } from 'react-redux';
import { deleteSchedule } from '../../states/schedule';
import { updateMachine } from '../../states/device';
import { convertTTT } from '../../utils';


const VideoItem = ({video, clients}) => (
  <div className="video-item">
    <div className="video-item__icon">
      <ion-icon name="play"></ion-icon>
    </div>
    <div className="video-item__detail">
      <div className="title">{video.title}</div>
      <div className="owner">Owned by {clients?.find(c => c.id===video.owner)?.name}</div>
    </div>
  </div>
)


const DeviceDetailContainer = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const { showNotificationSuccess, showNotificationError } = useNotification()
  const { confirmAction } = useConfirmation()
  const clients = useClients()
  const device = useDevice(params.deviceId)
  const deviceStore = useStore(device.store)
  const deviceStoreBooth = usePhotoBooth(deviceStore?.photobooth)
  const deviceStoreWheel = useSTWWheel(deviceStore?.stw)
  const deviceStoreVideos = useStoreVideos(device.store)
  const deviceSchedules = useDeviceSchedules(params.deviceId)

  // const [sortedSchedules, setSortedSchedules] = useState()
  const [[sortFunction], setSortFunction] = useState( [(a, b) => 0])

  const [activeSchedule, setActiveSchedule] = useState('video')
  const [activeScheduleIcon, setActiveScheduleIcon] = useState('play')
  const [editMode, setEditMode] = useState(false)

  const [showStorePicker , setShowStorePicker] = useState(false)
  
  const TimeSort = (a, b) => convertTTT(a.time) - convertTTT(b.time)
  
  const setScheduleProperties = (id, icon) => {
    setActiveSchedule(id)
    setActiveScheduleIcon(icon)
  }
  
  const handleScheduleDelete = async (schedule) => {
    const response = await confirmAction (`Are you sure you want to delete schedule (${schedule.name}) ?`)
    if (response) {
      try {
        await dispatch(deleteSchedule(schedule.id)).unwrap()
        showNotificationSuccess('Delete Success', '')
      } catch ( createError ) {
        console.error(createError)
        showNotificationError('Delete Error', '')
      }
    }
  }

  const handleMachineUpdate = async (values) => {
    try {
      await dispatch(updateMachine({...values})).unwrap()
      showNotificationSuccess('Update Success', `Machine data has been successfully updated`)
      setEditMode(false)
    } catch (machineUpdateError) {
      showNotificationError('Update Error', `Machine failed to update`)
      console.error('Schedule Update Error: ', machineUpdateError)
    }
  }

  
  const handleMachineFormUpdate = async (values) => {
    await handleMachineUpdate({machineId: device.id, ...values})
  }

  const handleMachineStoreUpdate = async (store) => {
    console.log('updating store to : ', store)
    if (store)
      await handleMachineUpdate({machineId: device.id, store: store[0]?.id})
    setShowStorePicker(false)
    setEditMode(false)
  }
  

  const SideMenuItem = ({label, icon, id})=> (
    <div 
      className={classNames("sidemenu__item", {'active': activeSchedule === id})}
      onClick={e => setScheduleProperties(id, icon)} 
    >
      <div className="icon"><ion-icon name={icon}></ion-icon></div>
      <div className="text">{label}</div>
    </div>
  )

  const handleStorePickerCancel = () => {
    setShowStorePicker(false)
  }

  return (
    <div className='device-detail-container'>
      <div className="background-wrapper">
        {showStorePicker && <StorePicker 
          handleCancel={handleStorePickerCancel} 
          handleSubmit={handleMachineStoreUpdate} 
          excludes={[deviceStore?.id]}
        /> }
      <Form onSubmit={handleMachineFormUpdate} initialValues={{...device}}>
        {({handleSubmit}) => (
        <>
        <div className="detail__header">
          <div className="detail__header--left">
            <span className="title">{device?.name}</span>
            <span className="id">{params.deviceId}</span>

            <span className="edit-btn" onClick={editMode ? handleSubmit : e => setEditMode(true)}>
              { editMode  ? <ion-icon name="save-outline"></ion-icon> :
              <ion-icon name="create-outline"></ion-icon> }
            </span>
          </div>
          <div className="detail__header--right">
            <StatusPill label="active" success={true} />
            <DateTimeBox />
          </div>
        </div>

        <div className="detail__info">

          <div className="detail__info-detail">
            <div className="label">location</div>
            <div className="title">{deviceStore?.name}</div>
            
            <div className="address">
              <span className="address__icon">
                <ion-icon name="location-outline"></ion-icon>
              </span>
              <div className="address__text">
                {deviceStore?.location?.address}, 
                {deviceStore?.location?.city}, 
                {deviceStore?.location?.state}, 
                {deviceStore?.location?.country},
              </div>
            </div>
            
            { editMode &&
            <span className='reassign' onClick={e => setShowStorePicker(true)}>
              (Re)-assign device store
            </span>
            }
          </div>

          <div className="detail__info-other">
          
            <div className="info-row">
              <span className='info-row__title'>Remote access</span>
              <div className="info-row__detail">         
                <div className="info-row__detail-item">
                  <span className='icon'><ion-icon name="person-outline"></ion-icon></span>
                  <span className='text'>
                    <Field name="remote_id">
                      {({input}) => ( <input {...input} disabled={!editMode} /> )}
                    </Field>
                  </span>
                </div>

                <div className="info-row__detail-item">
                  <span className='icon'><ion-icon name="lock-closed-outline"></ion-icon></span>
                  <span  className='text'>
                  <Field name="remote_pass">
                    {({input}) => ( <input {...input} type="text" disabled={!editMode} /> )}
                  </Field>
                  </span>
                </div>

              </div>
            </div>
            
            <div className="info-row">
              <span className='info-row__title' >License Number</span>
              <div className="info-row__detail">
                <div className="info-row__detail-item">
                  <span className='icon'><ion-icon name="ribbon-outline"></ion-icon></span>
                  <span className='text'>
                  <Field name="booth_license">
                    {({input}) => ( <input {...input} disabled={!editMode} /> )}
                  </Field>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="detail__videos">
          <div className="detail__videos-header">
            <span>Active Video ads</span>
          </div>
          <div className="detail__videos-content">
            {deviceStoreVideos.map(video =>  <VideoItem key={video.id} video={video} clients={clients} /> ) }
          </div>
        </div>

        <div className="detail__other-brands">
          <div className="brands-item">
            <div className="brands-item__header">photobooth</div>
            <div className="brands-item__content">
              <div className="icon"><ion-icon name="camera-outline"></ion-icon></div>
              <div className="detail">
                <span className="detail__title">{deviceStoreBooth?.name}</span>
                <span className="detail__name">Owned by {clients?.find(c => c.id===deviceStoreBooth?.owner)?.name}</span>
              </div>
            </div>
          </div>

          <div className="brands-item">
            <div className="brands-item__header">game spin the wheel</div>
            <div className="brands-item__content">
              <div className="icon"><ion-icon name="pie-chart-outline"></ion-icon></div>
              <div className="detail">
                <span className="detail__title">{deviceStoreWheel?.name ?? 'N/A'}</span>
                <span className="detail__name">Owned by {clients?.find(c => c.id===deviceStoreWheel?.owner)?.name}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="detail__schedules">
          <div className="detail__schedules__header">
            <span className="title">schedule</span>
            <ClientScheduleCreateForm device={device} activeSchedule={activeSchedule} />
          </div>
          <div className="detail__schedules__content">
            <div className="sidemenu">
              <SideMenuItem icon="play" label="video" id="video"/>
              <SideMenuItem icon="camera-outline" label="photo" id="photo" />
              <SideMenuItem icon="pie-chart-outline" label="game" id="game"/>
              <SideMenuItem icon="power-outline" label="offline" id="screen"/>
              <SideMenuItem icon="refresh-outline" label="restart" id="reboot"/>
              <SideMenuItem icon="stop-circle-outline" label="stop" id="stop"/>
            </div>
            <div className="body">
              <div className="sort-header">
                <SortBox onClick={e => setSortFunction([TimeSort]) }> Date </SortBox>
              </div>
              <div className="body__content">
                { deviceSchedules
                  .filter( schedule => schedule.name.toLowerCase().includes(activeSchedule)) 
                  .sort(sortFunction)
                  .map( schedule => (
                    <ClientScheduleItem 
                      schedule={schedule} 
                      key={schedule.id} 
                      icon={activeScheduleIcon}
                      handleItemDelete={handleScheduleDelete}
                    /> 
                  ))
                }
              </div>
            </div>
          </div>
        </div>
        </>
        )}
        </Form>
      </div>
    </div>
  );
};

export default DeviceDetailContainer;