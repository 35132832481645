import React, { useState } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Form, Field } from "react-final-form";
import {
  createPhotoBooth,
  deletePhotoBooth,
  updatePhotoBooth,
} from "../../states/photobooth";
import { setStoresBooth } from "../../states/store";
import DropzoneField from "../../components/fields/DropzoneField";
import TextInput from "../../components/fields/TextInput";
import PhotoBoothTableList from "../../components/booth/PhotoBoothTableList";
import ConfigStoreItem from "../../components/store/ConfigStoreItem";
import PhotoBoothStoreDevices from "../../components/booth/PhotoBoothStoreDevices";
import StoreTableList from "../../components/store/StoreTableList";
import StorePicker from "../../components/store/StorePicker";
import OutlineButton from "../../components/button/OutlineButton";
import { useNotification, useConfirmation } from "../../hooks";
import { usePhotoBooth } from "../../hooks/photobooth";
import { ReactComponent as AdminIcon } from "../../assets/admin-icon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/download-icon.svg";
import { ReactComponent as LinkIcon } from "../../assets/link-icon.svg";

const ClientConfigPhotoContainer = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { showNotificationSuccess, showNotificationError } = useNotification();
  const { confirmAction } = useConfirmation();
  const [titleEditMode, setTitleEditMode] = useState(false);
  const [currentBoothTitle, setCurrentBoothTitle] = useState("");
  const [currentBooth, setCurrentBooth] = useState();
  const currentBoothLiveData = usePhotoBooth(currentBooth?.id);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showStorePicker, setShowStorePicker] = useState(false);
  const [currentStore, setCurrentStore] = useState();
  const [currentMachine, setCurrentMachine] = useState();

  const handleBoothCreate = async (values) => {
    const { name, file } = values;
    try {
      const newFormData = new FormData();
      newFormData.append("file", file[0]);
      newFormData.append("name", name);
      newFormData.append("owner", params.clientId);
      await dispatch(
        createPhotoBooth({ formData: newFormData, onUploadProgress })
      ).unwrap();
      showNotificationSuccess("Theme Created", "successfull!");
    } catch (createError) {
      showNotificationError("Error", "Theme failed to update");
      console.error(createError);
    } finally {
      setUploadProgress(0);
    }
  };

  const handleBoothUpdate = async (data) => {
    try {
      const response = await dispatch(updatePhotoBooth({ ...data })).unwrap();
      showNotificationSuccess("Update Success", "Update successfull!");
      return response;
    } catch (videoDeleteError) {
      showNotificationError("Update Error", "Store failed to update");
    } finally {
    }
  };

  const handleBoothDelete = async (boothId) => {
    console.log("deleting booth : ", boothId);
    const response = await confirmAction(
      `Confirm you want to delete photobooth Item: ${boothId} ?`
    );
    if (response) {
      try {
        await dispatch(deletePhotoBooth({ boothId })).unwrap();
        showNotificationSuccess("Delete Success", "");
      } catch (deleteError) {
        console.error(deleteError);
        showNotificationError("Delete Error", "");
      }
    }
  };

  const handleBoothStoreDelete = async (store) => {
    const { id: storeId, name } = store;
    const response = await confirmAction(
      `Are you sure you want to remove booth from store: (${name})?`
    );
    if (response) {
      const filteredStores = currentBoothLiveData.stores.filter(
        (s) => s !== storeId
      );
      await handleBoothUpdate({
        boothId: currentBooth.id,
        stores: filteredStores,
      });
    }
  };

  const handleTitleEdit = async () => {
    if (titleEditMode) {
      console.log("saving title ... "); // do some dispatch from this point
      await handleBoothUpdate({
        boothId: currentBoothLiveData.id,
        name: currentBoothTitle,
      });
    }
    setTitleEditMode((p) => !p);
  };

  const onUploadProgress = (e) => {
    setUploadProgress(Math.round((100 * e.loaded) / e.total));
  };

  const onBoothItemClick = (booth) => {
    console.log("current booth: ", booth);
    setCurrentBooth(booth);
    setCurrentBoothTitle(booth.name);
    setCurrentStore();
  };

  const handleStorePickerCancel = () => {
    setShowStorePicker(false);
  };

  const handleStorePickerSubmit = async (stores) => {
    setShowStorePicker(false);
    if (currentBoothLiveData && stores) {
      const response = await handleBoothUpdate({
        boothId: currentBoothLiveData.id,
        stores: [...stores.map((s) => s.id), ...currentBoothLiveData.stores],
      });
      if (response) {
        // success
        console.log(
          "dispatch setStoreBooth: ",
          stores,
          currentBoothLiveData.id
        );
        dispatch(
          setStoresBooth({
            boothId: currentBoothLiveData.id,
            stores: [...stores.map((s) => s.id)],
          })
        );
      }
    }
  };

  const handleBoothConfigCopy = async () => {
    try {
      const configStr = `gallery/STOR_${currentMachine?.store}/`;
      await navigator.clipboard.writeText(configStr); // copy to clipboard
      showNotificationSuccess("Copy Success", "");
    } catch (copyError) {
      showNotificationError("Copy Fail", "");
      console.error("Copy Fail", copyError);
    }
  };

  const handleStoreAssign = (e) => {
    if (!currentBooth) return;
    setShowStorePicker(true);
  };

  return (
    <div className="client-photo-container">
      <div className="photo-config">
        {showStorePicker && (
          <StorePicker
            handleCancel={handleStorePickerCancel}
            handleSubmit={handleStorePickerSubmit}
            excludes={currentBoothLiveData?.stores}
          />
        )}

        <div className="photo-config__header">
          <span className="photo-config__header-title">photobooth</span>
        </div>

        <div className={classNames("photo-config__create-area")}>
          <div className="creator__header">NEW BOOTH THEME</div>
          <Form onSubmit={handleBoothCreate}>
            {({ handleSubmit, submitting }) => (
              <div className="creator__content">
                <div className="upload-box">
                  <Field name="file">
                    {({ input }) => <DropzoneField input={input} />}
                  </Field>
                </div>
                <div className="upload-input">
                  <Field name="name">
                    {({ input }) => (
                      <TextInput
                        {...input}
                        type="text"
                        placeholder="Enter Booth Label ..."
                      />
                    )}
                  </Field>
                  <OutlineButton onClick={handleSubmit} loading={submitting}>
                    Create
                  </OutlineButton>
                </div>
              </div>
            )}
          </Form>
        </div>

        <div className="photo-config__edit-area">
          <span>Theme name: </span>
          <div className="edit__content">
            <TextInput
              disabled={!titleEditMode}
              value={currentBoothTitle}
              onChange={(e) => setCurrentBoothTitle(e.target.value)}
            />
            <span onClick={handleTitleEdit}>
              <ion-icon
                name={`${titleEditMode ? "save" : "create"}-outline`}
              ></ion-icon>
            </span>
          </div>
        </div>

        <div className="photo-config__list-area">
          <div className="photos__header">
            <span>booth list</span>
          </div>

          <div className="photos__content">
            <PhotoBoothTableList
              clientId={params.clientId}
              onItemClick={onBoothItemClick}
              onItemDelete={handleBoothDelete}
              selectedId={currentBoothLiveData?.id}
            />
          </div>
        </div>

        <div className="photo-config__stores">
          <div className="work-area__header">
            <span className="work-area__header-title">add locations</span>
            <div
              className="work-area__header-input"
              onClick={handleStoreAssign}
            >
              Assign to one or more locations
              <ion-icon name="add-outline"></ion-icon>
            </div>
            <div className="work-area__header-button"></div>
          </div>
          <StoreTableList
            storesIds={currentBoothLiveData?.stores}
            onItemClick={(e) => setCurrentStore(e)}
            onItemDelete={handleBoothStoreDelete}
          />
        </div>

        <div className="photo-config__work-area">
          <div className="work-area__content">
            <div className="work-area__content-store-wrapper">
              <ConfigStoreItem store={currentStore} />
            </div>

            <div className="work-area__content-slab-wrapper">
              <div className="title">remote access vabe</div>
              <div className="content">
                <div className="shield">
                  <AdminIcon />
                  {/* <ion-icon name="shield-outline"></ion-icon> */}
                </div>

                <div className="remote-info">
                  <div className="remote-info__username">
                    <span>User: </span>
                    <span>{currentMachine?.remote_id ?? "N/A"}</span>
                  </div>
                  <div className="remote-info__pass">
                    <span>Pass: </span>
                    <span>{currentMachine?.remote_pass ?? "N/A"}</span>
                  </div>
                </div>

                <div className="theme-download">
                  <a download href={currentBoothLiveData?.file}>
                    {/* <ion-icon name="cloud-download-outline"> */}
                    <DownloadIcon />
                  </a>
                </div>

                <div
                  className="theme-url"
                  onClick={handleBoothConfigCopy}
                  style={{ cursor: "pointer" }}
                >
                  {/* <ion-icon name="unlink-outline"></ion-icon> */}
                  <LinkIcon />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="photo-config__devices-area">
          <PhotoBoothStoreDevices
            store={currentStore}
            onItemClick={(e) => setCurrentMachine(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default ClientConfigPhotoContainer;
